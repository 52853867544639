import dateType from '@/assets/constant/dateType';
import light from '@/theme/light';
import moment from 'moment';

export function bar(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: '',
			textStyle: {
				color: '#FFF',
				fontSize: 20
			},
		},
		grid: {
			left: '14%'
		},
		legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: res.legend || [],
		},
		toolbox: {
			show: true,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			textStyle: {
				color: 'black',
			},
			confine: true,
			formatter: '',
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			name: '',
			type: 'category',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					return moment(value).format(dateType.BRIEF_DATE);
				}
			},
			data: res.xAxis || [],
		},
		yAxis: [{
			name: '',
			type: 'value',
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: 'white' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				color: '#FFF',
			},
			// data: res.yAxis || [],
		}],
		series: res.series || [],
	};
	return option;
}
export function heatMap(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: res.subtext || '',
			sublink: '',
			textStyle: {
				color: '#fff',
				fontSize: 20
			},
		},
		grid: {
			left: '12%'
		},
		tooltip: {
			position: 'top',
			textStyle: {
				color: '#000',
			},
			formatter: '',
		},
		animation: false,
		xAxis: {
			type: 'category',
			position: 'top',
			data: res.xAxis || [],
			axisTick: { show: false },
			axisLine: { onZero: false, lineStyle: { color: '#fff' } },
			splitLine: { show: false, lineStyle: { color: '#fff' }, },
			splitArea: {
				show: true,
			},
			axisLabel: {
				color: '#FFF',
			},
		},
		yAxis: {
			type: 'category',
			data: res.yAxis || [],
			axisTick: { show: false },
			axisLine: { onZero: false, lineStyle: { color: '#fff' } },
			splitArea: {
				show: true,
			},
			splitLine: { show: false, lineStyle: { color: '#fff' }},
			axisLabel: {
				// interval: 'auto',
				interval: '0',
				color: '#FFF',
				formatter: value => {
					return moment(value).format(dateType.BRIEF_DATE);
				}
			},
		},
		visualMap: {
			show: true,
			min: res.min || 0,
			max: res.max || 100,
			calculable: false,
			color: res.color || light.color,
			orient: 'horizontal',
			left: 'center',
			textStyle: {
				color: '#fff',
			},
		},
		series:[
			{
				name: '',
				type: 'heatmap',
				data: res.series || [],
				label: {
					show: false,
				},
				// animation: false,
				itemStyle: {
					borderColor: 'black',
					borderWidth: 1
				},
				emphasis: {
					shadowBlur: 10,
					shadowColor: 'rgba(0, 0, 0, 0.5)',
				},
			}
		]
	};
	return option;
}