<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.totalGamingUsersCount')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.totalBet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.gmApvBetAmtPu')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avgGmSticky')
				v-row
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.totalTopUsersCount')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.totalTopUsersBet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.topUGmApvBetAmtPu')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.topUGmSticky')
				v-row.justify-center
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(pieOption1).length !== 0')
						v-chart(:option='pieOption1', ref='pieOption1', autoresize)
					ChartsSwitch(:firstOption='lineOption1' :secondOption='extendBetBar')
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(barOption1).length !== 0')
						v-chart(:option='barOption1', ref='barOption1', autoresize)
					v-col.chartWrap(cols='12', lg='8', xs='12', v-if='Object.keys(lineOption2).length !== 0')
						v-chart(:option='lineOption2', ref='lineOption2', autoresize)
					//- ChartsSwitch(:firstOption='lineOption2' :secondOption='extendNetWinBar')
				span
					v-col.chartWrapNoMargin 
						b {{ $t('charts.topMemberCp') }}
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(barOption2).length !== 0')
						v-chart(:option='barOption2', ref='barOption2', autoresize)
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(barOption3).length !== 0')
						v-chart(:option='barOption3', ref='barOption3', autoresize)
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(barOption4).length !== 0')
						v-chart(:option='barOption4', ref='barOption4', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='12', xs='12', @click='dialog = true' v-if='Object.keys(barOption5).length !== 0')
						v-chart(:option='barOption5', ref='barOption5', autoresize)
					v-dialog(v-model='dialog', max-width='500px', overlay-opacity='0.9', :retain-focus='false')
						v-card
							v-card-title
								span.text-center.headline {{ $t("customer.areYouSureYouWantToSearchMember") }}
								span.text-center {{ selectedMember }} {{ $t("customer.areYouSureYouWantToSearchMember2") }}
							v-col.d-flex.justify-space-between
								v-btn.mr-3(color='grey darken-2', dark, rounded, @click='closeDialog') {{ $t("common.cancel") }}
								router-link(target="_blank" to='/house/single', style='text-decoration: none; color: white')
									v-btn.defult-btn-color(dark, rounded, @click='jumpPage(selectedMember)') {{ $t("common.confirm") }}
				v-row
					v-col.chartWrap(cols='12', lg='12', xs='12', v-if='Object.keys(heatMapOption1).length !== 0')
						v-chart(v-if='heatMapYaxis < 20' @click='yAxisClick' :option='heatMapOption1', ref='heatMapOption1', autoresize)
						v-chart(v-else @click='yAxisClick' v-bind:style="{height: heatMapYaxis * 25 + 'px !important' }" :option='heatMapOption1', ref='heatMapOption1', autoresize)
					v-dialog(v-model='dialog', max-width='500px', overlay-opacity='0.9', :retain-focus='false')
						v-card
							v-card-title
								span.text-center.headline {{ $t("customer.areYouSureYouWantToSearchMember") }}
								span.text-center {{ selectedMember }} {{ $t("customer.areYouSureYouWantToSearchMember2") }}
							v-col.d-flex.justify-space-between
								v-btn.mr-3(color='grey darken-2', dark, rounded, @click='closeDialog') {{ $t("common.cancel") }}
								router-link(target="_blank" to='/house/single', style='text-decoration: none; color: white')
									v-btn.defult-btn-color(dark, rounded, @click='jumpPage(selectedMember)') {{ $t("common.confirm") }}
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import _ from 'lodash';
import { errorLogger } from '@/util/logger';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { getSiteTop } from '@/api/House';
import ChartsSwitch from '@/components/Charts/ChartsSwitch.vue';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import Kanban from '@/components/kanbans/Kanban';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import dateType from '@/assets/constant/dateType';
import statusCode from '@/assets/constant/statusCode';
import statusMsg from '@/util/http';
import timeIntervalType from '@/assets/constant/timeIntervalType';
import { bar, line, barPercent, pie, straightBar } from '@/assets/echarts/ocmsCommon/house/reg';
import { heatMap } from '@/assets/echarts/ocmsCommon/house/cvt';
import { exportXlsx } from '@/util/xlsx';
import COLORS from '@/theme/color';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	transfer,
	toThousandslsFilter,
	rateOfExchange,
	roundOff,
	roundOffFilter,
	rdPercent,
	roundDecimal,
	roundFilter,
	getNaN,
} from '@/util/format';

export default {
	components: {
		'v-chart': ECharts,
		ChartsSwitch,
		DaliyPicker,
		ExportBtn,
		Kanban,
		SingleSelectCurrency,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
			currencyStatus: true,
			show: false,
			tab: null,
			loading: false,
			percentSwitchTitle: this.$t('charts.showPercent'),
			dates: [],
			searchDates: [],
			dateType: dateType.BRIEF_DATE,
			selectedMember: '',
			heatMapYaxis: 0,
			imgSrc: require('@/assets/image/research.png'),
			topGroup: ['0.9', '0.95', '0.99'],
			nonTopGroup: ['0', '0.4', '0.8'],
			groupName: timeIntervalType['top'],
			groupKey: Object.keys(timeIntervalType['top']),
			groupValue: Object.values(timeIntervalType['top']),
			groupColor: [
				COLORS.belize_hole[3],
				COLORS.belize_hole[5],
				COLORS.belize_hole[7],
				COLORS.sunflower[3],
				COLORS.sunflower[5],
				COLORS.sunflower[8],
				COLORS.belize_hole[9],
				COLORS.sunflower[9]
			],
			otherColor: [
				COLORS.belize_hole[3],
				COLORS.belize_hole[5],
				COLORS.belize_hole[7],
				COLORS.sunflower[3],
				COLORS.sunflower[5],
				COLORS.sunflower[8],
			],
			cardData: {},
			topOrg: {},
			dailyBet: {},
			dailyNetWin: {},
			percentData: [],
			percentDataN: [],
			barOption1: {},
			barOption2: {},
			barOption3: {},
			barOption4: {},
			barOption5: {},
			extendBetBar: {},
			extendNetWinBar: {},
			heatMapOption1: {},
			lineOption1: {},
			lineOption2: {},
			pieOption1: {},
			xdata:[],
			layout:{}
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getSiteTop(reqData);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('currency', this.currency);
					const result = JSON.parse(JSON.stringify(res.result.res));
					// console.log('result', result);
					this.recapAll = result.ctx_recap_all[0];
					this.earlyRAll = result.ctx_recap_all_early[0];
					this.recapOrg = result.ctx_recap_org;
					this.recapSum = result.ctx_recap_sum;
					this.earlyRSum = result.ctx_recap_sum_early;
					this.termlyOrg = result.ctx_termly_org;
					this.termlyOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
					this.topMember = result.ctx_top_detail;

					this.dailyBet = trans(this.termlyOrg, 'dim_ptt', 'apv_bet');
					this.dailyNetWin = trans(this.termlyOrg, 'dim_ptt', 'house_net_win');
					this.topPieChart(this.recapOrg, this.recapSum);
					await this.topLineChart(this.termlyOrg);
					this.topBarChart(this.recapOrg);
					this.topStackBarChartForBet();
					// this.topStackBarChartForNetWin(); // not in use
					this.memberBarChart(this.topMember);
					this.heatMapChart(this.topMember);
					this.cardDisplay(this.earlyRSum, this.recapSum);
					this.getExportResult = JSON.parse(JSON.stringify(result));
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async cardDisplay(earlyRSum, recapSum) {
			for(let i of earlyRSum) {
				if(i.tag_top_ly1 === 'tops') this.earlyTops = i;
			}
			for(let i of recapSum) {
				if(i.tag_top_ly1 === 'tops') this.tops = i;
			}
			this.cardData = {
				totalGamingUsersCount: {
					mainTitle: this.$t('charts.avgDAU'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.recapAll.avg_gm_users,
					subData: this.earlyRAll.avg_gm_users,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalGamingUsersCount'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: this.recapAll.ttl_gm_users || 0,
					toolSubData: this.earlyRAll.ttl_gm_users || 0,
					note: 'people'
				},
				totalBet: {
					mainTitle: this.$t('charts.avgDailyBet'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.recapAll.avg_apv_bet,
					subData: this.earlyRAll.avg_apv_bet,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalBet'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: this.recapAll.ttl_apv_bet || 0,
					toolSubData: this.earlyRAll.ttl_apv_bet || 0,
					note: 'value'
				},
				gmApvBetAmtPu: {
					mainTitle: this.$t('charts.gmApvBetAmtPu'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.recapAll.pupd_apv_bet,
					subData: this.earlyRAll.pupd_apv_bet,
					note: 'value'
				},
				avgGmSticky: {
					mainTitle: this.$t('charts.avgGmSticky'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.recapAll.stickiness,
					subData: this.earlyRAll.stickiness,
					note: 'percent'
				},
				totalTopUsersCount: {
					mainTitle: this.$t('charts.totalTopUsersCount'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.tops.avg_gm_users,
					subData: this.earlyTops.avg_gm_users,
					tooltip: true,
					toolMainTitle: this.$t('charts.avgDAU'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: this.tops.ttl_gm_users || 0,
					toolSubData: this.earlyTops.ttl_gm_users || 0,
					note: 'people'
				},
				totalTopUsersBet: {
					mainTitle: this.$t('charts.totalTopUsersBet'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.tops.avg_apv_bet,
					subData: this.earlyTops.avg_apv_bet,
					tooltip: true,
					toolMainTitle: this.$t('charts.avgDailyBet'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: this.tops.ttl_apv_bet || 0,
					toolSubData: this.earlyTops.ttl_apv_bet || 0,
					note: 'value'
				},
				topUGmApvBetAmtPu: {
					mainTitle: this.$t('charts.topUGmApvBetAmtPu'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.tops.pupd_apv_bet,
					subData: this.earlyTops.pupd_apv_bet,
					note: 'value'
				},
				topUGmSticky: {
					mainTitle: this.$t('charts.topUGmSticky'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: this.tops.stickiness,
					subData: this.earlyTops.stickiness,
					note: 'percent'
				}
			}
		},
		async topPieChart(res, res2) {
			const betAmtData = [];
			let topBet = 0, normalBet = 0;
			const key = Object.keys(this.groupName);
			const legendData = Object.values(this.groupName);
			if (res) {
				for (let j of key) {
					for (let i = 0; i < res.length; i += 1) {
						if (j === res[i].tag_top_ly2) {
							betAmtData.push({
								value: res[i].avg_apv_bet || 0,
								name: this.groupName[j],
							});
						} else {
							betAmtData.push({
								value: 0,
								name: this.groupName[j],
							});
						}
					}
				}
				for (let i of res2) {
					if(i.tag_top_ly1 === 'tops') {
						topBet = i.avg_apv_bet;
					}
					if(i.tag_top_ly1 === 'others') {
						normalBet = i.avg_apv_bet;
					}
				}
				this.pieOption1 = pie({
					color: this.groupColor,
					title: this.$t('charts.topMemberBet'),
					legend: legendData,
					name: '',
					series: betAmtData,
				});
				this.pieOption1.tooltip.formatter = (params) => {
					return `${params.marker}${params.name}: ${roundOffFilter(params.value)} (${params.percent}%)`;
				};
				this.pieOption1.series.push({
					name: '',
					type: 'pie',
					radius: [0, '30%'],
					label: {
						position: 'inner',
						color: '#FFF'
					},
					labelLine: {
						show: false,
					},
					data: [
						{ name: this.$t('charts.normalMember'), value: normalBet },
						{ name: this.$t('charts.topMember'), value: topBet },
					],
				});
			}
		},
		async topLineChart(res) {
			let xAxisData = [];
			const tmp = {
				0: [],
				0.4: [],
				0.8: [],
				0.9: [],
				0.95: [],
				0.99: [],
			};
			const betAmtData = [],
				netWinData = [],
				tmpBetData = [], tmpNetWinData = [];
			const legendData = this.groupValue;
			if (res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					for (let j of this.groupKey) {
						if (i.tag_top_ly2 === j) {
							tmp[i.tag_top_ly2].push(i);
						}
					}
				}
				for (let i in tmp) {
					let betArr = [],
						netWinArr = [];
					let obj1 = {
						name: this.groupName[i],
						type: 'line',
						data: [],
					};
					let obj2 = {
						name: this.groupName[i],
						type: 'line',
						data: [],
					};
					for (let j of tmp[i]) {
						betArr.push(j.apv_bet);
						netWinArr.push(j.house_net_win);
					}
					obj1.data = betArr;
					betAmtData.push(obj1);
					obj2.data = netWinArr;
					netWinData.push(obj2);
				}
				xAxisData = Array.from(new Set(xAxisData));
				let allDataPercent = this.getPercent(tmp[0], tmp[0.4], tmp[0.8], tmp[0.9], tmp[0.95], tmp[0.99]);
				let allNetWinDataPercent = this.getNPercent(tmp[0], tmp[0.4], tmp[0.8], tmp[0.9], tmp[0.95], tmp[0.99]);
				for (let i in legendData) {
					tmpBetData.push({
						name: legendData[i],
						type: 'bar',
						stack: 'all',
						// color: this.groupColor[i],
						data: allDataPercent[i],
					});
					tmpNetWinData.push({
						name: legendData[i],
						type: 'bar',
						stack: 'all',
						// color: this.groupColor[i],
						data: allNetWinDataPercent[i],
					});
				}
					// if(allNetWinDataPercent[i] >= 0){
					// 	tmpNetWinData.push({
					// 		name: legendData[i],
					// 		type: 'bar',
					// 		stack: 'all',
					// 		// color: this.groupColor[i],
					// 		data: allNetWinDataPercent[i],
					// 	});
					// } else {
					// 	tmpNetWinData.push({
					// 		name: legendData[i],
					// 		type: 'bar',
					// 		stack: 'all',
					// 		// color: this.groupColor[i],
					// 		data: {value: allNetWinDataPercent[i], label: {position: 'right'}},
					// 	});
					// }
				// }
				this.percentData = tmpBetData;
				this.percentDataN = tmpNetWinData;
				this.lineOption1 = await line({
					color: this.groupColor,
					title: '',
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: betAmtData,
				});
				this.lineOption1.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
				this.lineOption2 = await line({
					color: this.groupColor,
					title: '',
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: netWinData,
				});
				this.lineOption2.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async topStackBarChartForBet() {
			const legendData = this.groupValue;
			const xAxisData = [];
			for(let i of this.dailyBet) {
				xAxisData.push(i.dim_ptt);
			}
			// this.dailyBet
			this.extendBetBar = await barPercent({
				color: this.groupColor,
				title: '',
				legend: legendData,
				name: '',
				xAxis: xAxisData,
				series: this.percentData,
			});
			this.extendBetBar.tooltip.formatter = (params) => {
				let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				for(let i of params) {
					te += `${i.marker}${i.seriesName}: ${roundDecimal(i.value)}%<br/>`;
				}
				return te;
			}
		},
		async topStackBarChartForNetWin() {
			const legendData = this.groupValue;
			const xAxisData = [];
			for(let i of this.dailyNetWin) {
				xAxisData.push(i.dim_ptt);
			}
			this.extendNetWinBar = await barPercent({
				color: this.groupColor,
				title: '',
				legend: legendData,
				name: '',
				xAxis: xAxisData,
				series: this.percentDataN,
			});
			// this.extendNetWinBar.yAxis[0].min = value => {
			// 	return value.min;
			// }
			// this.extendNetWinBar.yAxis[0].max = value => {
			// 	return value.max;
			// }
			this.extendNetWinBar.tooltip.formatter = (params) => {
				let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				for(let i of params) {
					te += `${i.marker}${i.seriesName}: ${roundDecimal(i.value)}%<br/>`;
				}
				return te;
			}
		},
		async topBarChart(res) {
			if (res) {
				const betArr = [],
					betTmp = [],
					netArr = []; //真實資料
				const xAxisData = ['0', '0.4', '0.8', '0.9', '0.95', '0.99'];
				const key = ['0.99', '0.95', '0.9', '0.8', '0.4', '0'];
				const yAxisData = this.groupValue;
				let gmArr = [],
					avgArr = [],
					avgPArr = [];
				for (let j of key) {
					for (let i of res) {
						if (j === i.tag_top_ly2) {
							// betTmp.push(i.ttl_apv_bet);
							// betArr.push(i.ttl_apv_bet);
							netArr.push(i.avg_house_net_win);
							gmArr.push(i.avg_gm_users);
							avgArr.push(i.avg_apv_bet);
							avgPArr.push(i.pupd_apv_bet);
						}
					}
				}
				gmArr.reverse();
				avgArr.reverse();
				avgPArr.reverse();
				netArr.reverse();
				this.barOption1 = bar({
					color: this.otherColor,
					title: this.$t('charts.topMemberNetWin'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption1.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							formatter: (params) => {
								return xAxisData[params.componentIndex];
							}
						},
						data: [netArr[i]]
					});
				}
				this.barOption1.title.textStyle.fontSize = 20;
				this.barOption1.grid.right = '15%';
				this.barOption1.yAxis[0].position = 'right';
				this.barOption1.tooltip.formatter = (params) => {
					let str = ``;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
					}
					return str;
				};
				this.barOption2 = bar({
					color: this.groupColor,
					title: this.$t('charts.topMemberCpGamingUsers'),
					// title: this.$t('charts.topMemberCp'),
					// subtext: this.$t('charts.topMemberCpGamingUsers'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < yAxisData.length; i += 1) {
					this.barOption2.series.push({
						name: yAxisData[i],
						type: 'bar',
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							align: 'left',
							verticalAlign: 'middle',
							formatter: '{a}',
							fontSize: 12,
							rich: {
								name: {
								}
							}
						},
						data: [gmArr[i]]
					});
				}
				this.barOption2.tooltip.formatter = (params) => {
					let str = ``;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
				this.barOption3 = bar({
					color: this.groupColor,
					title: this.$t('charts.topMemberCpAvgBet'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < yAxisData.length; i += 1) {
					this.barOption3.series.push({
						name: yAxisData[i],
						type: 'bar',
						data: [avgArr[i]]
					});
				}
				this.barOption3.yAxis[0].show = false;
				this.barOption3.tooltip.formatter = (params) => {
					let str = ``;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
					}
					return str;
				};
				this.barOption4 = bar({
					color: this.groupColor,
					title: this.$t('charts.topMemberCpAvgBetPu'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < yAxisData.length; i += 1) {
					this.barOption4.series.push({
						name: yAxisData[i],
						type: 'bar',
						data: [avgPArr[i]]
					});
				}
				this.barOption4.yAxis[0].show = false;
				this.barOption4.tooltip.formatter = (params) => {
					let str = ``;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
					}
					return str;
				};
			}
		},
		async memberBarChart(res) {
			const xAxisData = [],
				betArr = [],
				betSumTmp = [],
				rankTmp = [],
				prTmp = [];
			if (res) {
				res.sort((a, b) => (a.bet_pr < b.bet_pr ? 1 : -1));
				for (let i of res) {
					if(i.bet_pr >= 0.9) {
						xAxisData.push(i.dim_member_user_id);
						betSumTmp.push(i.bet_run_ratio);
						betArr.push(i.bet_amt);
						rankTmp.push(i.bet_rank);
						prTmp.push(i.bet_pr);
					}
				}
				this.barOption5 = straightBar({
					// color: COLORS.green_sea,
					title: this.$t('charts.topMemberCpBetRk'),
					name: '',
					xAxis: xAxisData,
					series: [
						{
							name: '',
							type: 'bar',
							data: betArr,
						},
						{
							name: '',
							type: 'line',
							yAxisIndex: 1,
							itemStyle: {
								color: COLORS.lighter_green,
							},
							data: betSumTmp,
						},
					],
				});
				this.barOption5.yAxis.push({
					type: 'value',
					name: '',
					min: 0,
					max: 100,
					interval: 10,
					axisLabel: {
						color: 'white',
						formatter: '{value} %',
					},
					splitLine: { show: true, lineStyle: { color: '#37474F' } },
				});
				this.barOption5.tooltip.formatter = (params) => {
					this.selectedMember = params[0].name;
					return `${params[0].name}<br/>
					RANK: ${toThousandslsFilter(rankTmp[params[0].dataIndex])}<br/>
					PR: ${roundDecimal(prTmp[params[0].dataIndex] * 100)}<br/>
					${params[0].marker}${roundOffFilter(params[0].value)}<br/>
					${params[1].marker}${roundDecimal(params[1].value)}%`;
				};
				this.barOption5.dataZoom[0].end = 100;
			}
		},
		async heatMapChart(res) {
			const days = [], memberList = [], rankList = [], prList = [], tmp = [], maxArr = [], data = [];
			if (res) {
				res.sort((a, b) => (a.bet_pr > b.bet_pr ? 1 : -1));
				for(let i of res) {
					if(i.bet_pr >= 0.99) {
						memberList.push(i.dim_member_user_id);
						rankList.push(i.bet_rank);
						prList.push(i.bet_pr);
						tmp.push(i.gm_term_sub);
					}
				}
				for(let i of tmp) {
					for(let j in i) {
						let obj = [];
						days.push(i[j].dim_ts.value);
						const diff = 30 - this.$moment(this.searchDates[1]).diff(i[j].dim_ts.value, 'days') - 1;
						obj.push(diff, tmp.indexOf(i), parseFloat(i[j].bet_amt));
						maxArr.push(parseFloat(i[j].bet_amt));
						data.push(obj);
					}
				}
				const xData = Array.from(new Set(days));
				xData.sort();
				this.heatMapYaxis = memberList.length;
				this.heatMapOption1 = await heatMap({
					title: this.$t('charts.topMemberStk'),
					color: [COLORS.sunflower[6], COLORS.sunflower[0]],
					name: '',
					xAxis: xData,
					yAxis: memberList,
					max: this.recapAll.pupd_apv_bet, // Math.max(...maxArr),
					series: data,
				});
				this.heatMapOption1.xAxis.axisLabel.formatter = value => {
					return this.$moment(new Date(value)).format(dateType.BRIEF_DATE);
				};
				this.heatMapOption1.yAxis.triggerEvent = true;
				this.heatMapOption1.yAxis.axisLabel.formatter = value => {
					return value;
				};
				this.heatMapOption1.tooltip.formatter = (params) => {
					return ` ${params.marker} ${memberList[params.data[1]]}<br/>
					${this.$t('charts.day')}: ${this.$moment(xData[params.data[0]]).format(dateType.BRIEF_DATE)}<br/>
					RANK: ${toThousandslsFilter(rankList[params.data[1]])}<br/>
					PR: ${roundDecimal(prList[params.data[1]] * 100)}<br/>
					${this.$t('charts.betAmount')}: ${roundOffFilter(params.data[2])}`;
				}
			}
		},
		yAxisClick(params) {
			if(params.componentType === 'yAxis') {
				this.dialog = true;
				this.selectedMember = params.value;
			}
		},
		getPercent(ze, ze4, ze8, ze9, ze95, ze99) {
			let zeArr = [], ze4Arr = [], ze8Arr = [], ze9Arr = [], ze95Arr = [], ze99Arr = [];
			for(let i of this.dailyBet) {
				for(let j of ze) {
					if(i.dim_ptt === j.dim_ptt) {
						zeArr.push((j.apv_bet / i.apv_bet * 100));
					}
				}
				for(let j of ze4) {
					if(i.dim_ptt === j.dim_ptt) {
						ze4Arr.push((j.apv_bet / i.apv_bet * 100));
					}
				}
				for(let j of ze8) {
					if(i.dim_ptt === j.dim_ptt) {
						ze8Arr.push((j.apv_bet / i.apv_bet * 100));
					}
				}
				for(let j of ze9) {
					if(i.dim_ptt === j.dim_ptt) {
						ze9Arr.push((j.apv_bet / i.apv_bet * 100));
					}
				}
				for(let j of ze95) {
					if(i.dim_ptt === j.dim_ptt) {
						ze95Arr.push((j.apv_bet / i.apv_bet * 100));
					}
				}
				for(let j of ze99) {
					if(i.dim_ptt === j.dim_ptt) {
						ze99Arr.push((j.apv_bet / i.apv_bet * 100));
					}
				}
			}
			let allDataPercent = [zeArr, ze4Arr, ze8Arr, ze9Arr, ze95Arr, ze99Arr];
			return allDataPercent;
		},
		getNPercent(ze, ze4, ze8, ze9, ze95, ze99) {
			let zeArr = [], ze4Arr = [], ze8Arr = [], ze9Arr = [], ze95Arr = [], ze99Arr = [];
			for(let i of this.dailyNetWin) {
				for(let j of ze) {
					if(i.dim_ptt === j.dim_ptt) {
						zeArr.push((j.house_net_win / i.house_net_win * 100));
					}
				}
				for(let j of ze4) {
					if(i.dim_ptt === j.dim_ptt) {
						ze4Arr.push((j.house_net_win / i.house_net_win * 100));
					}
				}
				for(let j of ze8) {
					if(i.dim_ptt === j.dim_ptt) {
						ze8Arr.push((j.house_net_win / i.house_net_win * 100));
					}
				}
				for(let j of ze9) {
					if(i.dim_ptt === j.dim_ptt) {
						ze9Arr.push((j.house_net_win / i.house_net_win * 100));
					}
				}
				for(let j of ze95) {
					if(i.dim_ptt === j.dim_ptt) {
						ze95Arr.push((j.house_net_win / i.house_net_win * 100));
					}
				}
				for(let j of ze99) {
					if(i.dim_ptt === j.dim_ptt) {
						ze99Arr.push((j.house_net_win / i.house_net_win * 100));
					}
				}
			}
			let allDataPercent = [zeArr, ze4Arr, ze8Arr, ze9Arr, ze95Arr, ze99Arr];
			return allDataPercent;
		},
		jumpPage() {
			localStorage.setItem(
				'searchMember',
				JSON.stringify({
					area: this.area,
					domain: this.value,
					member: this.selectedMember,
				})
			);
			this.closeDialog();
			// this.$emit('searchMember', JSON.stringify({
			// 	area: this.area,
			// 	domain: this.value,
			// 	member: this.selectedMember,
			// }));
			// this.$router.push({ path: '/house/single' });
		},
		allClean() {
			this.cardData = {};
			this.barOption1 = {};
			this.barOption2 = {};
			this.barOption3 = {};
			this.barOption4 = {};
			this.barOption5 = {};
			this.extendBetBar = {};
			this.extendNetWinBar = {};
			this.heatMapOption1 = {};
			this.lineOption1 = {};
			this.lineOption2 = {};
			this.pieOption1 = {};
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.area = val2;
			this.currency = val3;
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	watch: {
		tab: function () {
			this.percentSwitch = false;
			this.percentSwitch2 = false;
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { top: NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getDialogShow', 'getStatusDialog', 'getOperatorDefaultRouter', 'getOpenAutoSearch']),
	},
	created() {},
};
</script>